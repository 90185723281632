import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://2a9ca76447d7fa9fd20464fcf7e697e3@o4506302186455040.ingest.sentry.io/4506302190780416",
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
